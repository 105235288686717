import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import PageHeroPattern from '../components/heros/PageHeroPattern'
import PageHeroImage from '../components/heros/PageHeroImage'


const NotFoundPage = () => {
  return (
    <Layout pageTitle={'Page Not Found'}>
      <PageHeroPattern pageTitle={'Page Not Found'} />
      {/*<PageHeroImage pageTitle={'Page Not Found'} />*/}
      <section>
        <div className="content">
          <h2 className="section-title">Oops, this page was not found</h2>
          <p className={'center'}>Go back to the home page and try to navigate to the page you are looking for</p>
          <div className="button-group center">
            <Link to={'/'}><button className={'secondary'}>Go To Home Page</button></Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
