import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const PageHeroPattern = ({ pageTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "triangle.svg" }) {
          publicURL
        }
      }
    `
  )
  return (
    <div className="hero page pattern" style={{ backgroundImage: `url('${data.desktop.publicURL}')` }}>
      <div className="content">
        <h1 className="headline">{pageTitle}</h1>
      </div>
    </div>
  )
}

export default PageHeroPattern
